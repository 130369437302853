import { base, mainnet, optimism } from 'viem/chains';

import type { ConfigEntry } from './config.types';
import { DOT_ORG_TEAM, NDAO_WALLETS } from './helpers';

export const config: ConfigEntry = {
  environmentName: 'production',
  chains: [mainnet, base, optimism],
  baseUrls: {
    app: 'https://app.endaoment.org',
    marketing: 'https://endaoment.org',
    api: 'https://api.endaoment.org',
    admin: 'https://admin.endaoment.org',
    developers: 'https://developers.endaoment.org',
    docs: 'https://docs.endaoment.org',
  },
  endaoment: {
    accounts: {
      accountant: ['0x9D5025B327E6B863E5050141C987d988c07fd8B2'],
      maintainer: [NDAO_WALLETS.ZACH, NDAO_WALLETS.ROBBIE, NDAO_WALLETS.RICARDO, NDAO_WALLETS.PEDRO],
      reviewer: ['0xBE21e4cf884c8B2517E4E199487f8b505841cB36'],
      keeper: [...DOT_ORG_TEAM],
    },
  },
  socialLoginChainId: base.id,
  zeroDevProjectId: '46197405-dbfa-4bfd-8fed-359696a1d0c5',
  privyAppId: 'clvmg8vf6057btmusn43z3oef',
  circleAddress: '0xf0bfff7045dfeb4f6e69350c1cf8ff1a7de0d7da',
  googleMapsApiKey: 'AIzaSyBNX_ixICIxKEUHNiWFGdBBbi42OD4GN7k',
  mapboxApiKey: 'pk.eyJ1IjoiZW5kYW9tZW50IiwiYSI6ImNsaGo5MDRmdzBmZXozZnBiMHV1NWRjcGIifQ.HfvViRsi4sKeYxFYt7-BQw',
  identifiersSecret: 'f33f3c5a-a716-4a5a-94bf-6cf7d86199a83a4cfa0a-1a52-4c30-98c9-7bc38ab6bbb3',
};
